// based on this https://codesandbox.io/s/neat-dialogs-3h5ou?from-embed
import * as React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button
} from "@material-ui/core";

import { Lottie } from '@crello/react-lottie';
import successAnimation from './lotties/success.json';
import errorAnimation from './lotties/error.json';
import questionAnimation from './lotties/question.json';
import warningAnimation from './lotties/warning.json';
import infoAnimation from './lotties/info.json';
import deleteAnimation from './lotties/delete.json';

export const ConfirmDialog = ({
	open,
	title,
	variant,
	description,
	type,
	onSubmit,
	onClose
}) => {

	let lottieOptions = {
		loop: true,
		autoplay: true, 
		// path: `/lottie/${type}.json`,
		animationData: null
	};

	switch (type) {
		case 'success':
			lottieOptions.animationData = successAnimation;
			break;
		case 'error':
			lottieOptions.animationData = errorAnimation;
			break;
		case 'question':
			lottieOptions.animationData = questionAnimation;
			break;
		case 'warning':
			lottieOptions.animationData = warningAnimation;
			break;
		case 'info':
			lottieOptions.animationData = infoAnimation;
			break;
		case 'delete':
			lottieOptions.animationData = deleteAnimation;
			break;
		default:
			break;
	}

	return (
		<Dialog className="infoDialog" open={open}>
			<Lottie 
				className="dialogLottieAnimation"
				config={lottieOptions}
    	        height={250}
				width={250}
			/>
			<DialogTitle className="dialogTitle">{title !== undefined ? title : ''}</DialogTitle>
			<DialogContent>
				<DialogContentText className="dialogDescription">{description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				{variant === "question" && (
					<React.Fragment>
						<Button className="dialogButton grayBackgroundButton" color="primary" onClick={onClose}>
							ΟΧΙ
            			</Button>
						<Button className="dialogButton blueBackgroundButton" color="primary" onClick={onSubmit} autoFocus>
							ΝΑΙ
            			</Button>
					</React.Fragment>
				)}

				{variant === "info" && (
					<Button className="dialogButton blueBackgroundButton" color="primary" onClick={onSubmit} autoFocus>
						OK
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
