import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Lottie } from '@crello/react-lottie';
import CircularProgress from '@material-ui/core/CircularProgress';

import successAnimation from '../../partials/dialogs/lotties/success.json';
import errorAnimation from '../../partials/dialogs/lotties/error.json';
import { verifyAccount } from "../../api/auth";

export const VerifyAccount = (props) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const params = useParams();

	useEffect(() => {
		requestVerifyAccount();
	}, [])

	const requestVerifyAccount = () => {
		verifyAccount(params.token)
		.then(res => {
			setLoading(false);
			setError('');
		})
		.catch(err => {
			if (err.response && (err.response.status === 404 || err.response.status === 401)) {
				setError('Υπήρξε πρόβλημα με την επιβεβαίωση του λογαριασμού σας. Δοκιμάστε να κάνετε κλικ ξανά στον σύνδεσμο που λάβατε με email.')
			} else {
				setError('Προέκυψε σφάλμα κατά την επιβεβαίωση του λογαριασμού σας. Δοκιμάστε ξανά και αν το πρόβλημα παραμένει επικοινωνήστε με την υποστήριξη!')
			}
			setLoading(false);
		})
	}

	return (
		<div className="kt-login__body">
			<div className="kt-login__form">
				<div className="kt-login__title mb-4">
					<h3>Επιβεβαίωση του λογαριασμού σας</h3>
				</div>

				{loading && 
					<div>
						<p className="text-center mb-5">Παρακαλούμε περιμένετε όσο γίνεται η επιβεβαίωση του λογαριασμού σας</p>
						<div className="d-flex justify-content-center">
							<CircularProgress size={70} thickness={5} color="inherit" />
						</div>
					</div>
				}

				{!loading && error === '' && 
					<React.Fragment>
						<Lottie 
							className="ml-auto mr-auto"
							config={{
								loop: true,
								autoplay: true,
								animationData: successAnimation
							}}
							height={250}
							width={250}
						/>

						<div className="dialogDescription text-center">
							Η επιβεβαίωση του λογαριασμού σας ολοκληρώθηκε επιτυχώς! Μπορείτε να συνδεθείτε στην πλατφόρμα.
						</div>

						<div className="d-flex justify-content-center mt-4">
							<Link
								className="btn btn-primary"
								to="/auth/login"
							>
								<span className="button-text">ΣΥΝΔΕΣΗ</span>
							</Link>
						</div>

					</React.Fragment>
				}

				{!loading && error !== '' && 
					<React.Fragment>
						<Lottie 
							className="ml-auto mr-auto"
							config={{
								loop: true,
								autoplay: true,
								animationData: errorAnimation
							}}
							height={200}
							width={200}
						/>

						<div className="dialogDescription text-center">
							{error}
						</div>
					</React.Fragment>
				}
			</div>
		</div>
	);
}
