import React from "react";
import { Button } from 'react-bootstrap';

import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-5.scss";

export function ErrorPage() {
	return (
		<>
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div
					className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5"
					style={{
						backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`
					}}
				>
					<div className="kt-error_container">
						<span className="kt-error_title">
							<h1>Ουπς!</h1>
						</span>
						<p className="kt-error_subtitle">Κάτι πήγε στραβά!</p>
						<p className="kt-error_description">
							Μάλλον προσπαθήσετε να μπείτε σε κάποια σελίδα
							<br />
							που δεν υπάρχει ή έχει μεταφερθεί...
							<br />
							Επιστρέψτε πίσω και δοκιμάστε ξανά.
							<br />
							<br />
							<br />
							<Button variant="secondary" onClick={() => window.history.back()}>Επιστροφή</Button>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
