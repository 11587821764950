import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { changePassword } from "../../api/auth";

export const ResetPassword = (props) => {
	const [submitted, setSubmitted] = useState(false);
	const [responseError, setResponseError] = useState(false);

	const params = useParams();

	const renderResult = (status) => {
		return responseError ? (
			<div role="alert" className="alert alert-danger">
				<div className="alert-text">{status}</div>
			</div>
		) : (
			<div role="alert" className="alert alert-success">
				<div className="alert-text">{status}</div>
			</div>
		)
	}

	return (
		<div className="kt-login__body">
			<div className="kt-login__form">
				<div className="kt-login__title">
					<h3>
					{/* <FormattedMessage id="AUTH.FORGOT.TITLE" /> */}
					Αλλαγή Κωδικού Πρόσβασης
					</h3>
				</div>

				<Formik
					initialValues={{ email: "" }}
					validate={values => {
						const errors = {};

						if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(values.password)) {
							errors.password = 'Συμπληρώστε έναν κωδικό με τουλάχιστον 8 χαρακτήρες που να περιλαμβάνει αριθμούς, γράμματα και σύμβολα.'
						}

						if (values.passwordVerify !== values.password) {
							errors.passwordVerify = 'Οι κωδικοί πρόσβασης δεν ταιριάζουν.'
						}

						return errors;
					}}
					onSubmit={(values, { setStatus, setSubmitting }) => {
						changePassword(params.token, values.password)
							.then(() => {
								setSubmitted(true);
								setResponseError(false);
								setStatus('Ο κωδικός πρόσβασης άλλαξε!')
							})
							.catch(() => {
								setSubmitting(false);
								setSubmitted(false);
								setResponseError(true);
								setStatus('Ο σύνδεσμος που χρησιμοποιήσατε για την αλλαγή κωδικού έχει λήξει. Η ισχύς του συνδέσμου είναι 2 ώρες από τη στιγμή που θα ζητήσετε την αλλαγή κωδικού.');
							});
					}}
				>
					{({
						values,
						status,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting
					}) => (
						<form onSubmit={handleSubmit} className="kt-form">
						{status && renderResult(status)}

						<div className="form-group">
							<TextField
								type="password"
								label="Κωδικός Πρόσβασης"
								margin="normal"
								fullWidth={true}
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								// value={values.password}
								helperText={touched.password && errors.password}
								error={Boolean(touched.password && errors.password)}
							/>

							<TextField
								type="password"
								label="Επαλήθευση Κωδικού Πρόσβασης"
								margin="normal"
								fullWidth={true}
								name="passwordVerify"
								onBlur={handleBlur}
								onChange={handleChange}
								// value={values.passwordVerify}
								helperText={touched.passwordVerify && errors.passwordVerify}
								error={Boolean(touched.passwordVerify && errors.passwordVerify)}
							/>
						</div>

						<div className="kt-login__actions">
							<Link to="/auth">
								<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
									Επιστροφή
								</button>
							</Link>

							<button type="submit" className="btn btn-primary btn-elevate kt-login__btn-primary" disabled={isSubmitting}>
								{submitted && responseError ? 'Επανάληψη' : 'Αλλαγή Κωδικού'}
							</button>

						</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}
