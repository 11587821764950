import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { forgotPassword } from "../../api/auth";

class ForgotPassword extends Component {
  state = { isRequested: false, responseError: false };

  renderResult(status) {
	  return this.state.responseError ? (
			<div role="alert" className="alert alert-danger">
			<div className="alert-text">{status}</div>
			</div>
		) : (
			<div role="alert" className="alert alert-success">
				<div className="alert-text">{status}</div>
			</div>
		)
  }

  render() {
    const { isRequested, responseError } = this.state;

    // if (isRequested) {
    //   return <Redirect to="/auth" />;
    // }

    return (
          <div className="kt-login__body">
            <div className="kt-login__form">
              <div className="kt-login__title">
                <h3>
                  {/* <FormattedMessage id="AUTH.FORGOT.TITLE" /> */}
                  Επαναφορά Κωδικού Πρόσβασης
                </h3>
              </div>

              <Formik
                  initialValues={{ email: "" }}
                  validate={values => {
                    const errors = {};

                    if (!values.email) {
                    //   errors.email = intl.formatMessage({
                    //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
                    //   });
                        errors.email = 'Το email είναι απαραίτητο.'
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                    //   errors.email = intl.formatMessage({
                    //     id: "AUTH.VALIDATION.INVALID_FIELD"
                    //   });
                        errors.email = 'Το email δεν είναι έγκυρο.'
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setStatus, setSubmitting }) => {
                    forgotPassword(values.email)
                        .then(() => {
						  this.setState({ isRequested: true, responseError: false });
						  setStatus('Σύντομα θα λάβετε ένα email με οδηγίες για την επαναφορά του κωδικού σας.')
                        })
                        .catch(() => {
						  setSubmitting(false);
						  this.setState({ isRequested: true, responseError: true })
                          setStatus(
                            //   intl.formatMessage(
                            //       { id: "AUTH.VALIDATION.NOT_FOUND" },
                            //       { name: values.email }
                            //   )
                            'Το email που εισάγατε δεν αντιστοιχεί σε κάποιον χρήστη.'
                          );
                        });
                  }}
              >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} className="kt-form">
					  {status && this.renderResult(status)}

                      <div className="form-group">
                        <TextField
                            type="email"
                            label="Email"
                            margin="normal"
                            fullWidth={true}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                            error={Boolean(touched.email && errors.email)}
                        />
                      </div>

                      <div className="kt-login__actions">
                        <Link to="/auth">
                          <button
                              type="button"
                              className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                          >
                            Επιστροφή
                          </button>
                        </Link>

                        <button
                            type="submit"
                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                            disabled={isSubmitting}
                        >
                          {isRequested && responseError ? 'Επανάληψη' : 'Επαναφορά'}
                        </button>
                      </div>
                    </form>
                )}
              </Formik>
            </div>
          </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
