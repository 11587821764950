import * as utils from "../../_metronic/utils/utils";

const localStorageLastLocationKey = "lastLocation";

function acceptLocation(lastLocation) {
	return (
		lastLocation &&
		lastLocation.pathname &&
		lastLocation.pathname !== "/" &&
		lastLocation.pathname.indexOf("auth") === -1 &&
		lastLocation.pathname !== "/logout"
	);
}

export function saveLastLocation(lastLocation) {
	const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
	let _useLocations = localStorageLocations ? JSON.parse(localStorageLocations) : [];

	if (acceptLocation(lastLocation)) {
		_useLocations.push(lastLocation.pathname);
		utils.setStorage(
			localStorageLastLocationKey,
			JSON.stringify(_useLocations),
			120
		);
	}
}

export function forgetLastLocation() {
	utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
	const localStorageLocations = utils.getStorage(localStorageLastLocationKey);
	if (!localStorageLocations) {
		return "/";
	}

	const _userLocations = JSON.parse(localStorageLocations);
	return _userLocations.length > 0 ? _userLocations.pop() : "/";
}
