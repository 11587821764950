import axios from "axios";
import { config } from '../../config';

const ENDPOINT_URL = "auth/";
const API_BASE_URL = config.API_BASE_URL;

export const login = (email, password) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}login`, { email, password });
}

export const register = (data) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}register`, data);
}

export const forgotPassword = (email) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}lost-password`, { email })
}

export const changePassword = (token, password) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}change-password`, { token, password })
}

export const profileChangePassword = (previous, password) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}profile-change-password`, { previous, password })
}

export const getUserByToken = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}`);
}

export const refreshToken = (refreshToken) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}token`, { refreshToken });
}

export const resendVreificationEmail = (email) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}resend-verify-email`, { email });
}

export const verifyAccount = (verifyToken) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}verify-account`, { verifyToken });
}