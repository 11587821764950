import React, { useState, useEffect } from "react";
import Fab from '@material-ui/core/Fab';
import _ from 'lodash';
import Check from 'react-feather/dist/icons/check';
import X from 'react-feather/dist/icons/x';
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';

import { config } from '../../../config';
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { getAsMember } from '../../api/me';
import { getMyPayments } from '../../api/payments';
import { listNotAccepted } from '../../api/users';
import { ReactComponent as AddUser  } from '../../../assets/Add-user.svg';

import './dashboard.css';

const Dashboard = (props) => {

	const history = useHistory();

	const [memberData, setMemberData] = useState();
	const [paymentsData, setPaymentsData] = useState([]);
	const [notAcceptedMembersData, setNotAcceptedMembersData] = useState([]);
	const [loadingMember, setLoadingMember] = useState(true);
	const [loadingPayments, setLoadingPayments] = useState(true);
	const [attempts, setAttempts] = useState(0);

	useEffect(() => {
		fetchMemberData();
		fetchPaymentsData();
		if (props.userPermissions?.includes('editMembers')) {
			fetchPendingMembersList();
		}
	}, [])

	const fetchMemberData = () => {
		setAttempts(old => old + 1);
		getAsMember().then(res => {
			console.log('[DASHBOARD] - MEMBER DATA', res.data);
			setMemberData(res.data);
			setLoadingMember(false);
		}).catch(err => {
			if (err.response.status === 401) {
				if (attempts < 6) {
					setTimeout(() => {
						fetchMemberData();
					}, 1000);
				}
			}
			setLoadingMember(false);
			console.error('Error while getting user data as member', err);
		});
	};

	const fetchPaymentsData = () => {
		setAttempts(old => old + 1);
		getMyPayments().then(res => {
			const paymentsData = res.data.payments;
			console.log('[DASHBOARD] PAYMENTS DATA', paymentsData);
			setPaymentsData(paymentsData);
			setLoadingPayments(false);
		}).catch(err => {
			if (err.response.status === 401) {
				if (attempts < 6) {
					setTimeout(() => {
						fetchPaymentsData();
					}, 1000);
				}
			}
			setLoadingPayments(false)
			console.error('Error while getting my payments data', err);
		})
	};

	const fetchPendingMembersList = () => {
		listNotAccepted().then(res => {
			console.log('[DASHBOARD] PENDING MEMBERS DATA', res.data);
			setNotAcceptedMembersData(res.data);
		}).catch(err => {
			console.error('Error while getting pending members list', err);
		});
	};
	
	return (
		<div className="dashboard">
			<div id="kt_subheader" className="kt-subheader kt-grid__item">
				<h3 className="kt-subheader__title">Το προφίλ μου</h3>
			</div>
			<div className="row">
				<div className="col-md-6">
					{ props?.userPermissions?.includes('editMembers') &&
						<Portlet>
							<PortletBody>
								<div className="col justify-content-center align-items-center d-flex p-5 rounded" style={{ background: '#ebf4ff' }}>
									<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
										<AddUser/>
									</span>
									<div onClick={() => history.push('/members/members-to-accept')} style={{ cursor: 'pointer' }} className="justify-content-center pl-3 text-primary font-weight-bold d-flex align-items-center">
										<span className="pr-3" style={{ fontSize: '3rem' }}>{notAcceptedMembersData?.length}</span>
										{notAcceptedMembersData?.length === 1 ? 'Μέλος περιμένει' : 'Μέλη περιμένουν'} αποδοχή
									</div>
								</div>
							</PortletBody>
						</Portlet>
					}
					{ !loadingMember && 
						<Portlet>
							<PortletBody>
								{ memberData &&
									<div className="col-12 pictureNameColumn">
										{memberData.picture ? 
											<div className="image-container">
												<Avatar src={`${config.API_BASE_URL}uploads/profile_pic/${memberData.picture}`} style={{ width: 150, height: 150 }} />
											</div>
										: 
										<div className="initialLettersContainer">{memberData.name.charAt(0).toUpperCase() + memberData.surname.charAt(0).toUpperCase()}</div>
										}
										<div className="fullName">{memberData.name} {memberData.surname}</div>
										{memberData.regnum && 
											<div className="regnum">ΑΜ: <span>{memberData.regnum}</span></div>
										}
										<div className="email">{memberData.email}</div>
										<Fab
											variant="extended"
											size="medium"
											color="primary"
											aria-label="Συμπλήρωση Στοιχείων"
											onClick={() => history.push('/profile/edit')}
											style={{ marginTop: 20 }}
										>
											<span className="button-text">ΕΠΕΞΕΡΓΑΣΙΑ ΠΡΟΦΙΛ</span>
										</Fab>
									</div>					
								}
							</PortletBody>
						</Portlet>
					}
				</div>


				<div className="col-md-6">
					{ !loadingPayments && 
						<Portlet>
							<PortletBody>
								{ !!memberData ?
									<div className="memberData">
										<div className="subscription-title">
											Η συνδρομή μου
										</div>
										{
											(memberData.registrationdate || memberData.retirementyear || memberData.deregisterdate) ?
											<div className="member-date">
												{memberData.registrationdate &&
													<p>
														Μέλος από: { memberData.registrationdate }
													</p>
												}

												{memberData.retirementyear &&
													<p>
														Συνταξιοδοτήθηκα το: {memberData.retirementyear}
													</p>
												}

												{memberData.deregisterdate &&
													<p>
														Απεγγράφηκα στις: {memberData.deregisterdate}
													</p>
												}
											</div>
											:
											<div className="text-center">Δεν υπάρχουν καταχωρημένες πληροφορίες στο προφίλ σας...</div>
										}
										<div className="subscription-title" style={{ marginTop: 40 }}>
											Πληρωμές Συνδρομών
										</div>
										<div className="subscription-list">
											{ (paymentsData && paymentsData.length > 0) ? 
												paymentsData.map((payment, index) => 
												<div key={'subscr' + index} className="subscription-item">
													<div className="year">{payment.year}</div>
														{payment.paid ? 
															<div className={'payment-status-' + payment.paid}><Check/> Πληρώθηκε</div>
														:
															<div className={'payment-status-' + payment.paid}><X/> Εκκρεμεί</div>
													}
												</div>
												)
												:
												<div className="text-center">Δεν υπάρχουν καταχωρημένες πληροφορίες στο προφίλ σας...</div>
											}
										</div>
									</div>
									:
									<div className="noMemberData">
										<img
											alt="member data"
											src={toAbsoluteUrl("/media/images/member-card.png")}
											className="memberCard"
										/>
										<p>Ολοκληρώστε την εγγραφή σας ως μέλος του συλλόγου συμπληρώνοντας όλα τα προσωπικά σας στοιχεία.</p>
										<Fab
											variant="extended"
											size="medium"
											color="primary"
											aria-label="Συμπλήρωση Στοιχείων"
											onClick={() => history.push('/profile/edit')}
										>
											<span className="button-text">ΣΥΜΠΛΗΡΩΣΗ ΣΤΟΙΧΕΙΩΝ</span>
										</Fab>
									</div>
								}
							</PortletBody>
						</Portlet>
					}
				</div>

			</div>
		</div>
	);
}

const mapStateToProps = store => ({
  userPermissions: store.auth?.user?.permissions
});

export default connect(mapStateToProps)(Dashboard);