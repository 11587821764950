import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../api/auth";

const Registration = (props) => {

	const [responseError, setResponseError] = useState(false);

	const renderResult = (status) => {
		return responseError ? (
			  <div role="alert" className="alert alert-danger">
			  <div className="alert-text">{status}</div>
			  </div>
		  ) : (
			  <div role="alert" className="alert alert-success">
				  <div className="alert-text">{status}</div>
			  </div>
		  )
	}

	return (
		<div className="kt-login__body">
			<div className="kt-login__form">
				<div className="kt-login__title">
					<h3>
						{/* <FormattedMessage id="AUTH.REGISTER.TITLE" /> */}
						Εγγραφή Μέλους
					</h3>
				</div>

				<Formik
					initialValues={{
						email: "",
						name: "",
						latinname: "",
						surname: "",
						latinsurname: "",
						fathersname: "",
						idnumber: "",
						password: "",
						// acceptTerms: true,
						confirmPassword: ""
					}}
					validate={values => {
						const errors = {};

						if (!values.email) {
							errors.email = 'To email είναι απαραίτητο.'
						} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						) {
							errors.email = 'Το email δεν είναι έγκυρο.'
						}

						if (!values.name) {
							errors.name = 'Το όνομα είναι απαραίτητο.';
						}

						if (!values.surname) {
							errors.surname = 'Το επώνυμο είναι απαραίτητο.'
						}

						if (!values.latinname) {
							errors.latinname = 'Το όνομα με λατινικούς χαρακτήρες είναι απαραίτητο.';
						}

						if (!values.latinsurname) {
							errors.latinsurname = 'Το επώνυμο με λατινικούς χαρακτήρες είναι απαραίτητο.'
						}
						
						if (!values.fathersname) {
							errors.fathersname = 'Το πατρώνυμο είναι απαραίτητο.'
						}

						if (!values.idnumber) {
							errors.idnumber = 'Ο αριθμός ταυτότητας είναι απαραίτητος.'
						}
						
						if (!values.password) {
							errors.password = 'Ο κωδικός είναι απαραίτητος.'
						}

						if (!values.confirmPassword) {
							errors.confirmPassword = 'Η επιβεβαίωση κωδικού είναι απαραίτητη.'
						} else if (values.password !== values.confirmPassword) {
							errors.confirmPassword = 'Οι κωδικοί πρόσβασης δεν ταιριάζουν.';
						}

						// if (!values.acceptTerms) {
						// 	errors.acceptTerms = 'Πρέπει να αποδεχτείτε τους όρους χρήσης.';
						// }

						return errors;
					}}
					onSubmit={(values, { setStatus, setSubmitting }) => {
						const submitData = {
							email: values.email,
							name: values.name,
							surname: values.surname,
							latinname: values.latinname,
							latinsurname: values.latinsurname,
							fathersname: values.fathersname,
							idnumber: values.idnumber,
							password: values.password
						};

						register(submitData)
						.then(() => {
							setStatus('Ο λογαριασμός σας έχει δημιουργηθεί. Ελέγξε το email σας. Θα λάβετε σύντομα ένα μήνυμα για να ενεργοποιήσετε τον λογαριασμό σας και να συνδεθείτε.')
							setResponseError(false);
							window.scrollTo(0, 0);
							// The user should recieve an email to verify his account.
						})
						.catch((err) => {
							console.log('ERROR RESPONSE', err);
							if (err.response && err.response.status === 420) {
								setStatus('Το email που δηλώσατε αντιστοιχεί σε κάποιον χρήστη. Αν δεν θυμάστε τον κωδικό σας μπορείτε να χρησιμοποιήσετε το κουμπί "Ξέχασα τον κωδικό μου" για να αλλάξετε τον κωδικό πρόσβασης σας.')
							} else {
								setStatus('Προέκυψε σφάλμα κατά την δημιουργία του λογαριασμού σας. Δοκιμάστε ξανά. Αν το πρόβλημα παραμείνει παρακαλούμε ενημερώστε μας.')
							}
							setResponseError(true);
							setSubmitting(false);
							window.scrollTo(0, 0);
						});
					}}
					>
					{({
						values,
						status,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting
					}) => (
						<form onSubmit={handleSubmit} noValidate autoComplete="off">
						{status && renderResult(status) }

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Όνομα"
								className="kt-width-full"
								name="name"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.name}
								helperText={touched.name && errors.name}
								error={Boolean(touched.name && errors.name)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Όνομα με Λατινικούς Χαρακτήρες"
								className="kt-width-full"
								name="latinname"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.latinname}
								helperText={touched.latinname && errors.latinname}
								error={Boolean(touched.latinname && errors.latinname)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Επώνυμο"
								className="kt-width-full"
								name="surname"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.surname}
								helperText={touched.surname && errors.surname}
								error={Boolean(touched.surname && errors.surname)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Επώνυμο με Λατινικούς Χαρακτήρες"
								className="kt-width-full"
								name="latinsurname"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.latinsurname}
								helperText={touched.latinsurname && errors.latinsurname}
								error={Boolean(touched.latinsurname && errors.latinsurname)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Πατρώνυμο"
								className="kt-width-full"
								name="fathersname"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.fathersname}
								helperText={touched.fathersname && errors.fathersname}
								error={Boolean(touched.fathersname && errors.fathersname)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								margin="normal"
								label="Αριθμός Ταυτότητας"
								className="kt-width-full"
								name="idnumber"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.idnumber}
								helperText={(touched.idnumber && errors.idnumber) ?? 'Ο αριθμός ταυτότηας είναι απαραίτητος για την έκδοση της κάρτας μέλους του συλλόγου' }
								error={Boolean(touched.idnumber && errors.idnumber)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								label="Email"
								margin="normal"
								className="kt-width-full"
								name="email"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.email}
								helperText={touched.email && errors.email}
								error={Boolean(touched.email && errors.email)}
							/>
						</div>

						<div className="form-group mb-0">
							<TextField
								type="password"
								margin="normal"
								label="Κωδικός"
								className="kt-width-full"
								name="password"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.password}
								helperText={touched.password && errors.password}
								error={Boolean(touched.password && errors.password)}
							/>
						</div>

						<div className="form-group">
							<TextField
								type="password"
								margin="normal"
								label="Επιβεβαίωση Κωδικού"
								className="kt-width-full"
								name="confirmPassword"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.confirmPassword}
								helperText={touched.confirmPassword && errors.confirmPassword}
								error={Boolean(
									touched.confirmPassword && errors.confirmPassword
								)}
							/>
						</div>

						{false && <div className="form-group mb-0">
							<FormControlLabel
								label={
									<>
									Συμφωνώ με τους{" "}
									<Link
										to="/terms"
										target="_blank"
										rel="noopener noreferrer"
									>
										Όρους Χρήσης
									</Link>
									</>
								}
								control={
									<Checkbox
										color="primary"
										name="acceptTerms"
										onBlur={handleBlur}
										onChange={handleChange}
										checked={values.acceptTerms}
									/>
								}
							/>
						</div>}

						<div className="kt-login__actions">
							<Link
								to="/auth/forgot-password"
								className="kt-link kt-login__link-forgot"
								>
								{/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
								Ξέχασα τον κωδικό μου
							</Link>

							<Link to="/auth">
							<button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
								Άκυρο
							</button>
							</Link>

							<button
								disabled={
									isSubmitting //|| !values.acceptTerms
								}
								className="btn btn-primary btn-elevate kt-login__btn-primary"
							>
							Εγγραφή
							</button>
						</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Registration)
);
