/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
	render() {
		const { user, showHi, showAvatar, showBadge } = this.props;

		return (
			<Dropdown
				className="kt-header__topbar-item kt-header__topbar-item--user"
				drop="down"
				alignRight
			>
				<Dropdown.Toggle
					as={HeaderDropdownToggle}
					id="dropdown-toggle-user-profile"
				>
					<div
						className="kt-header__topbar-wrapper"
						data-toggle="dropdown"
						data-offset="10px,0px"
					>
						{showHi && (
							<span className="kt-header__topbar-welcome">
								Καλωσόρισες,
							</span>
						)}

						{showHi && (
							<span className="kt-header__topbar-username">
								{user.name}
							</span>
						)}

						{/*{showAvatar && <img alt="Pic" src={user.pic} />}*/}

						{showBadge && (
							<span className="kt-header__topbar-icon">
								<b>{user && user.name?.charAt(0)?.toUpperCase() + user.surname?.charAt(0)?.toUpperCase()}</b>
							</span>
						)}
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
					<div className="kt-notification">
						<div className="kt-notification__custom" style={{ justifyContent: 'center' }}>
							<Link
								to="/logout"
								className="btn btn-label-brand btn-sm btn-bold"
							>
								Αποσύνδεση
              				</Link>
						</div>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapStateToProps = ({ auth: { user } }) => ({
	user
});

export default connect(mapStateToProps)(UserProfile);
