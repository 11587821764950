import axios from "axios";
import { config } from '../../config';

const ENDPOINT_URL = "users/";
const API_BASE_URL = config.API_BASE_URL;

export const getUserAsMemberWithLanguages = (id) => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}as-member-with-languages/${id}`);
}

export const listNotAccepted = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}list-not-accepted`);
}

export const acceptUser = (id, newStatusIsAccept) => {
    const action = newStatusIsAccept === true ? 'accept' : 'decline';
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}${action}/${id}`);
}

export const resetPasswordByAdmin = (userId) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}${userId}/reset-password-by-admin`);
}