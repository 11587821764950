import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as routerHelpers from '../../router/RouterHelpers';

export const actionTypes = {
    StoreTokens: '[StoreTokens] Action',
    Login: '[Login] Action',
    Logout: '[Logout] Action',
};

const initialAuthState = {
    user: undefined,
    token: undefined,
    refreshToken: undefined,
};

export const reducer = persistReducer({
    storage,
    key: 'auth',
    whitelist: [
        'user',
        'token',
        'refreshToken',
    ],
}, (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.StoreTokens: {
            // User data will be updated with a subsequent request to the API (see saga below)
            return {
                ...state,
                refreshToken: action.payload.refreshToken,
                token: action.payload.token,
            };
        }

        case actionTypes.Login: {
            console.log('[Auth] User loaded');
            return {
                ...state,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken,
                user: action.payload.user,
            };
        }

        case actionTypes.Logout: {
            routerHelpers.forgetLastLocation();
            return initialAuthState;
        }

        default:
            return state;
    }
});

export const actions = {
    loginAction: (token, refreshToken, user) => ({
        type: actionTypes.Login,
        payload: {
            token,
            refreshToken,
            user,
        },
    }),
    storeTokensAction: (token, refreshToken) => ({
        type: actionTypes.StoreTokens,
        payload: {
            token,
            refreshToken,
        },
    }),
    logoutAction: () => ({ type: actionTypes.Logout }),
};
