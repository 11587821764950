import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';

import Dashboard from './Dashboard';
import { LayoutSplashScreen } from '../../../_metronic';
import * as AuthAPI from '../../api/auth';
import * as auth from '../../store/ducks/auth.duck';

const ProfilePage = lazy(() => import('./profile/ProfilePage'));
const MembersPage = lazy(() => import('./members/MembersPage'));
const PaymentsPage = lazy(() => import('./payments/PaymentsPage'));
const AdminsPage = lazy(() => import('./admins/AdminPage'));

class HomePage extends React.Component {

	componentDidMount() {
		this.refreshTheToken();
	}

	refreshTheToken() {
		try {
			console.log('TOKEN IN REFRESH TOKEN CHECK', this.props.token);
			let timeToExpireToken = jwt_decode(this.props.token).exp - new Date().getTime()/1000;
			let timeToExpireRefreshToken = jwt_decode(this.props.refreshToken).exp - new Date().getTime()/1000;

			console.log('[AUTH - TOKEN VALID FOR]', timeToExpireToken);
			console.log('[AUTH - REFRESH TOKEN VALID FOR]', timeToExpireRefreshToken);

			const timeout = setTimeout(async () => {

				// Checking if the Refresh Token will expire
				if (timeToExpireRefreshToken > 10) {

					console.log('[AUTH - REFRESHING TOKEN]');

					try {
						const res = await AuthAPI.refreshToken(this.props.refreshToken);
						this.props.storeTokensAction(res.data.token, res.data.refreshToken);
						console.log('[AUTH - TOKEN REFRESHED]');

						// Recursive calling the function to repeat the refresh
						this.refreshTheToken();
					} catch (error) {
						console.log('[AUTH - ERROR WHILE REFRESHING THE TOKEN]', error);
						// The refresh token attempt failed. Must redirect to login
						this.props.logoutAction();
					}

				} else {
					console.log('[AUTH - REFRESH TOKEN EXPIRED]', timeToExpireToken);
					// Removing the timeout to avoid leaks
					timeout.clearTimeout();
					// Redirect to login
					this.props.logoutAction();
				}

			},(timeToExpireToken - 10)*1000); // refresh it 10 seconds before expiration
		} catch (error) {
			this.props.logoutAction();
		}
	}

	render() {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<Switch>
					<Redirect exact from='/' to='/dashboard' />
					<Route path='/dashboard' component={Dashboard} />
					<Route path='/profile' component={ProfilePage} />
					<Route path='/members' component={MembersPage} />
					<Route path='/payments' component={PaymentsPage} />
					<Route path='/admins' component={AdminsPage} />
					<Redirect to='/error' />
				</Switch>
			</Suspense>
		);
	}
}

const mapStateToProps = ({ auth: { user, token, refreshToken } }) => ({
	token, refreshToken, user,
});

export default connect(mapStateToProps, auth.actions)(HomePage);
