import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login, resendVreificationEmail } from "../../api/auth";
import { useConfirmation } from '../../partials/dialogs/ConfirmDialogService';

function Login(props) {
	// Initializing the custom confirmation dialog instance
	const dialogConfirmation = useConfirmation();

	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({
		paddingRight: "2.5rem"
	});
	const [sendingEmailAgain, setSendingEmailAgain] = useState(false);

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	const requestResendVerificationEmail = (email) => {
		setSendingEmailAgain(true);
		resendVreificationEmail(email)
		.then(res => {
			setSendingEmailAgain(false);
			dialogConfirmation({
				variant: 'info',
				type: 'success',
				catchOnCancel: false,
				title: 'Τέλεια!',
				description: 'Σύντομα θα λάβετε νέο email για την επιβεβαίωση του λογαριασμού σας. Παρακαλούμε περιμένετε τουλάχιστον 10 λεπτά πριν αποστείλετε και άλλο αίτημα αποστολής email επιβεβαίωσης και βεβαιωθείτε ότι έχετε ελέγξει τον φάκελο με την ανεπιθύμητη αλληλογραφία.'
			})
		})
		.catch(err => {
			if (err.response && err.response.status === 404) {
				dialogConfirmation({
					variant: 'info',
					type: 'warning',
					catchOnCancel: false,
					title: 'Προσοχή!',
					description: 'Το email που έχετε συμπληρώσει δεν αντιστοιχεί σε κάποιον χρήστη'
				})
			} else {
				dialogConfirmation({
					variant: 'info',
					type: 'warning',
					catchOnCancel: false,
					title: 'Ουπς!',
					description: 'Υπήρξε πρόβλημα κατά την αποστολή του email επιβεβαίωσης. Δοκιμάστε ξανά και αν το πρόβλημα παραμένει επικοινωνήστε με την υποστήριξη!'
				})
			}
			setSendingEmailAgain(false);
			console.error('Error while trying to send verification email again', err);
		})
	}

	return (
		<>
			{/* <div className="kt-login__head">

      </div> */}

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>
							{/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
							{/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
							Είσοδος στον λογαριασμό σας
						</h3>
					</div>

	  				{/* TODO FIXME remove initial values */}
					<Formik
						initialValues={{
							email: '',
							password: ''
						}}
						validate={values => {
							const errors = {};

							if (!values.email) {
								// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
								errors.email = 'Το email είναι απαραίτητο';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = 'Μη έγκυρο email. Διορθώστε το email που εισάγατε.'
							}

							if (!values.password) {
								errors.password = 'Ο κωδικός είναι απαραίτητος'
							}

							return errors;
						}}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();
							login(values.email, values.password)
								.then(({ data }) => {
									props.loginAction(data.token, data.refreshToken, data.member);
									disableLoading();
									setSubmitting(false);
								})
								.catch((err) => {
									disableLoading();
									setSubmitting(false);

									if (err.response) {
										switch (err.response.status) {
											case 401: setStatus({ message: 'Τα στοιχεία πρόσβασης δεν είναι σωστά.', errorCode: err.response.status }); break;
											case 420: setStatus({ message: 'Δεν έχει γίνει επιβεβαίωση του λογαριασμού σας. Θα έχετε λάβει ένα email στον λογαριασμό email που δηλώσατε κατά την εγγραφή σας. Αν δεν έχετε λάβει το μήνυμα αυτό κάντε κλικ στο παρακάτω κουμπί.', errorCode: err.response.status }); break;
											default: setStatus({ message: 'Πρόβλημα κατά την είσοδο. Επικοινωνήστε με την υποστήριξη', errorCode: err.response.status }); break;
										}
									} else {
										setStatus({ message: 'Πρόβλημα κατά την είσοδο. Επικοινωνήστε με την υποστήριξη', errorCode: 500 });
									}
								});
						}}
					>
						{({
							values,
							status,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
						}) => (
								<form
									noValidate={true}
									autoComplete="off"
									className="kt-form"
									onSubmit={handleSubmit}
								>
									{status && (
										<div role="alert" className="alert alert-danger d-flex flex-column">
											<div className="alert-text">{status.message}</div>
											{
												status.errorCode === 420 &&
												<button
													id="kt_login_signin_submit"
													onClick={() => requestResendVerificationEmail(values.email)}
													disabled={sendingEmailAgain}
													className={`mt-3 btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
														{
															"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": sendingEmailAgain
														}
													)}`}
													style={loadingButtonStyle}
												>
													Αποστολή email επιβεβαίωσης ξανά!
												</button>
											}
										</div>
									)}

									<div className="form-group">
										<TextField
											type="email"
											label="Email"
											margin="normal"
											className="kt-width-full"
											name="email"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.email}
											helperText={touched.email && errors.email}
											error={Boolean(touched.email && errors.email)}
										/>
									</div>

									<div className="form-group">
										<TextField
											type="password"
											margin="normal"
											label="Κωδικός"
											className="kt-width-full"
											name="password"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.password}
											helperText={touched.password && errors.password}
											error={Boolean(touched.password && errors.password)}
										/>
									</div>

									<div className="kt-login__actions">
										<Link
											to="/auth/forgot-password"
											className="kt-link kt-login__link-forgot"
										>
											Ξεχάσατε τον κωδικό σας;
                    {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
										</Link>

										<button
											id="kt_login_signin_submit"
											type="submit"
											disabled={isSubmitting}
											className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
												{
													"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
												}
											)}`}
											style={loadingButtonStyle}
										>
											Είσοδος
                  						</button>
									</div>
									<div style={{ textAlign: 'center' }}>
										<span className="kt-login__signup-label">Δεν έχετε λογαριασμό;</span>
										&nbsp;&nbsp;
										<Link to="/auth/registration" className="kt-link kt-login__signup-link">Εγγραφείτε!</Link>
									</div>
								</form>
							)}
					</Formik>
				</div>
			</div>
		</>
	);
}

export default injectIntl(
	connect(
		null,
		auth.actions
	)(Login)
);
