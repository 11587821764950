import axios from "axios";
import { config } from '../../config';

const ENDPOINT_URL = "payments/";
const API_BASE_URL = config.API_BASE_URL;

export const getMyPayments = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}mine`);
}

export const getAll = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}`);
}

export const updatePaymentsForMember = (id, data) => {
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}${id}`, data);
}

export const listMembersForPayment = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}list-users`);
}

export const listMembersThatOwe = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}list-members-that-owe`);
}

export const deletePayment = (id) => {
    return axios.delete(`${API_BASE_URL}${ENDPOINT_URL}${id}`);
}