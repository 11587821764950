import * as React from "react";
import { ConfirmDialog } from "./ConfirmDialog";

const ConfirmDialogServiceContext = React.createContext(Promise.reject);

export const useConfirmation = () =>
	React.useContext(ConfirmDialogServiceContext);

export const ConfirmServiceProvider = ({ children }) => {
	const [
		confirmationState,
		setConfirmationState
	] = React.useState(null);

	const awaitingPromiseRef = React.useRef();

	const openConfirmation = (options) => {
		setConfirmationState(options);
		return new Promise((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject };
		});
	};

	const handleClose = () => {
		if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject();
		}

		setConfirmationState(null);
	};

	const handleSubmit = () => {
		if (awaitingPromiseRef.current) {
			awaitingPromiseRef.current.resolve();
		}

		setConfirmationState(null);
	};

	return (
		<React.Fragment>
			<ConfirmDialogServiceContext.Provider
				value={openConfirmation}
				children={children}
			/>

			<ConfirmDialog
				open={Boolean(confirmationState)}
				onSubmit={handleSubmit}
				onClose={handleClose}
				{...confirmationState}
			/>
		</React.Fragment>
	);
};
