export default {
	header: {
		self: {},
		items: [
			{
				title: "Επεξεργασία προφίλ",
				root: true,
				alignment: "left",
				page: "profile/edit",
				permissions: ['member']
				// hidden: true,
				// translate: "MENU.INCOME",
			},
			{
				title: "Μέλη",
				root: true,
				alignment: "left",
				page: "members",
				// hidden: true,
				// translate: "MENU.INCOME",
				toggle: "click",
				permissions: ['viewMembers', 'editMembers'],
				submenu: [
					{
						title: "Λίστα μελών",
						page: "members/filtered",
						icon: "flaticon-list-1",
						permissions: ['viewMembers', 'editMembers']
					},
					{
						title: "Λίστα μελών προς αποδοχή",
						page: "members/members-to-accept",
						icon: "flaticon-list-1",
						permissions: ['editMembers']
					},
					{
						title: "Νέο μέλος",
						page: "members/add",
						icon: "flaticon-file-1",
						permissions: ['editMembers']
					}
				]
			},
			{
				title: "Πληρωμές",
				root: true,
				alignment: "left",
				page: "payments",
				// hidden: true,
				// translate: "MENU.INCOME",
				toggle: "click",
				permissions: ['payments'],
				submenu: [
					{
						title: "Λίστα πληρωμών",
						page: "payments/list",
						icon: "flaticon-list-1",
						permissions: ['payments']
					},
					{
						title: "Λιστά μελών που χρωστούν",
						page: "payments/members-that-owe",
						icon: "flaticon-list-1",
						permissions: ['payments']
					},
					{
						title: "Καταχώρηση πληρωμής",
						page: "payments/select-member",
						icon: "flaticon-file-1",
						permissions: ['payments']
					}
				]
			},
			{
				title: "Διαχειριστές",
				root: true,
				alignment: "left",
				page: "admins",
				// hidden: true,
				// translate: "MENU.INCOME",
				toggle: "click",
				permissions: ['editAdmins'],
				submenu: [
					{
						title: "Λίστα διαχειριστών",
						page: "admins/list",
						icon: "flaticon-list-1",
						permissions: ['editAdmins']
					}
				]
			},
		]
	}
};
