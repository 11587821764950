import axios from "axios";
import { config } from '../../config';

const ENDPOINT_URL = "me/";
const API_BASE_URL = config.API_BASE_URL;

export const getAsMember = () => {
    return axios.get(`${API_BASE_URL}${ENDPOINT_URL}as-member`);
}

export const updateMyProfile = (data, avatar) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('avatar', avatar);
    return axios.post(`${API_BASE_URL}${ENDPOINT_URL}`, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}